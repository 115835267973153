import React from "react";

const Dot = () => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.685547"
        y="0.538574"
        width="10"
        height="10"
        rx="5"
        fill="#006EF5"
      />
    </svg>
  );
};

export default Dot;
