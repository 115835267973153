import styled, { css } from 'styled-components';
import { Container, getBoldFont, Icon, Text } from '@mooncake/ui';
import { StyledTooltipContainer } from '../Tooltip/styles';

export const PastilleColor = '#006fe6';

export const CustomContainer = styled(Container)`
    ${StyledTooltipContainer} {
        z-index: 1;
        opacity: 0;
        transition: all ${(props) => props.theme.transitionDuration} ease-in-out;
        transform: scaleY(0);
        visibility: hidden;
    }
    &:not(.is-upc) {
        ${StyledTooltipContainer} {
            opacity: 1;
            visibility: visible;
            transform: none;
            ${(props) => props.theme.breakpoints.tabletLarge.min.css`
                opacity: 0;
                visibility: hidden;
            `};
        }
    }

  ${StyledTooltipContainer}.active {
    ${(props) => props.theme.breakpoints.tabletLarge.min.css`
      opacity: 1;
      transform: none;
      visibility: visible;
    `};
  }

  &.is-upc {
    ${StyledTooltipContainer}.active{
      ${(props) => props.theme.breakpoints.tablet.min.css`
        opacity: 1;
        transform: none;
        visibility: visible;
        background-color: ${props.theme.colors.background.primary};
      `};
    }
  }

  ${(props) => props.theme.breakpoints.tabletLarge.min.css`
    text-align: center;
  `}
`;

export const CustomInnerContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerLink = styled.div`
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  padding: 12px 16px;
  border-radius: 30px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.text.primary};
  cursor: pointer;
  line-height: 1rem;

  &:hover, &.active {
    background-color: rgba(255, 255, 255, 0.075);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:focus-visible {
    outline: 2px solid black;
    background-color: ${(props) => props.theme.colors.SchoolBusYellow};
    color: black;
  }

  &:not(.is-upc) {
    ${(props) => props.theme.breakpoints.tabletLarge.max.css`
      display: none;
    `};
  }

`;

export const CustomIcon = styled(Icon)`
  display: block;

  * {
    fill: ${(props) => props.color};
  }

  font-size: 1.5rem;

  ${(props) => props.theme.breakpoints.desktopXLarge.min.css`
    font-size: 1rem;
  `}
`;

const WalletBaseText = styled(Text.Span)`
  font-size: 14px;
`;

export const WalletText = styled(WalletBaseText)`
  display: none;

  ${(props) => props.theme.breakpoints.desktopXLarge.min.css`
    white-space: nowrap;
    display: block;
    margin-right: 0.25rem;
    text-transform: capitalize;
    ${getBoldFont}
  `};
`;

export const BalanceText = styled(WalletBaseText)`
  ${(props) => props.theme.breakpoints.tablet.min.css`
    display: none;
  `};

  ${(props) =>
    !props.canShowRewards &&
    css`
      &::after {
        content: ':';
      }
    `}
`;

export const BalanceAmount = styled(WalletBaseText)`
  ${(props) => props.theme.breakpoints.desktopXLarge.min.css`
    &::before {
      content: "(";
    }
    &::after {
      content: ")";
      }
      `};

  white-space: nowrap;
  ${(props) => props.theme.breakpoints.tabletLarge.min.css`
    display: block;
    color: ${(props) => props.color.default};
    flex-grow: 1;
    max-width: 100%;
    color: ${(props) => props.color.tabletLarge};
  `};
`;

export const Pastille = styled(Container)`
  top: 0;
  right: 0;
  display: block;
`;

export const createMobilePastille = (hasRewardsToClaim) => {
  const mobileMenuButton = document.querySelector('#navigation .navigation-wrapper .top-mobile-menu');
  if (mobileMenuButton) {
    let mobilePastille = mobileMenuButton.querySelector('.mobile-pastille');
    if (hasRewardsToClaim && !mobilePastille) {
      mobilePastille = document.createElement('span');
      mobilePastille.className = 'mobile-pastille';
      mobileMenuButton.appendChild(mobilePastille);
    } else if (!hasRewardsToClaim && mobilePastille) {
      mobilePastille.remove();
    }
  }
};

export const injectCSSMobilePastille = (theme) => {
  const style = document.createElement('style');
  const cssContent = css`
    .top-mobile-menu {
      position: relative;
    }
    .top-mobile-menu .mobile-pastille {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      position: absolute;
      top: 12px;
      right: 12px;
      background-color: ${PastilleColor};
    }
    ${theme.breakpoints.tabletLarge.min.css`
      .top-mobile-menu .mobile-pastille {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        top: 14px;
        right: 14px;
      }
    `}
  `;
  style.textContent = cssContent.join('');
  document.head.appendChild(style);
};
