/**
 * Instruction to add language manually: The country/locale code should be in kebab case
 * Example: en-US.json
 * import enUS from "./en-US";
 * export default {
 *   ...
 *   "en-US": enUS
 * }
 */
import de from "./de";
import en from "./en";
import enANZ from "./en-ANZ";
import enCA from "./en-CA";
import enUS from "./en-US";
import es from "./es";
import esEs from "./es-ES";
import fr from "./fr";
import itIt from "./it-IT";
import nlNl from "./nl-NL";
import plPl from "./pl-PL";
import ptBr from "./pt-BR";
import zh from "./zh";

export default {
  de: de,
  en: en,
  "en-ANZ": enANZ,
  "en-CA": enCA,
  "en-US": enUS,
  es: es,
  "es-ES": esEs,
  fr: fr,
  "it-IT": itIt,
  "nl-NL": nlNl,
  "pl-PL": plPl,
  "pt-BR": ptBr,
  zh: zh
};
