export default {
  breakpoints: {
    mobile: 0,
    tablet: 0,
    tabletLarge: 1025,
    desktop: 1280,
    desktopLarge: 1470,
    desktopXLarge: 1920
  },
  colors: {
    background: {
      primary: '#242424'
    },
    text: {
      primary: '#f2f2f2',
      secondary: '#b5b5b5'
    }
  },
};
