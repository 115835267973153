import React from "react";
import { Container, Loader } from "@mooncake/ui";
import { CustomContainer, CustomIcon } from "../Content/styles";
import { Wallet } from "@mooncake/ui/lib/icons";
import { withTheme } from "styled-components";

const AppLoader = () => (
  <CustomContainer>
    <Container display={"inline-flex"} maxWidth={"100%"} width={"100"} height={"40px"} alignItems={"center"}>
      <Container marginLeft={"16px"} position={"relative"} marginRight={"8px"}>
        <CustomIcon
          icon={Wallet}
          color={"currentColor"}
          verticalAlign="middle"
        />
      </Container>
      <Container flexShrink={"0"} width={"60px"} position={"relative"}>
        <Loader zIndex={0} dotsColor={"currentColor"} />
      </Container>
    </Container>
  </CustomContainer>
);

export default withTheme(AppLoader);
