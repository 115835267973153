import PropTypes from 'prop-types';

export const CustomAppPropTypes = {
  link: PropTypes.string.isRequired,
  linkToTopupPage: PropTypes.string.isRequired,
  linkToPrepaidRedeemPage: PropTypes.string,
  isUPC: PropTypes.bool,
};

export const CustomAppDefaultProps = {};

export const CustomAppEventsConfigs = {};
