import { Container, getBoldFont } from '@mooncake/ui';
import styled from 'styled-components';

export const StyledTooltipContainer = styled(Container)`
    position: absolute;
    top: 100%;
    left: 0;
  ${(props) => props.theme.breakpoints.tabletLarge.min.css`
    margin-top: 5px;
  `};
  ${(props) => props.theme.breakpoints.tablet.min.css`
    transform-origin: center top;
    white-space: nowrap;
  `};
`;

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  padding: 13.5px 16px;
  margin: 8px 0;
  text-align: left;
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.primary};
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;

  span {
    white-space: nowrap;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.075);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:focus-visible {
    background-color: ${(props) => props.theme.colors.SchoolBusYellow};
    color: black;
  }

  ${(props) => props.theme.breakpoints.tabletLarge.min.css`
    font-size: 14px;
  `};
`;

export const StyledPriceContainer = styled.div`
  white-space: nowrap;
  margin-left: 20px;
  ${getBoldFont};
`;

export const StyledCurrencyContainer = styled(Container)`
  gap: 5px;
  background-color: rgba(255, 255, 255, 0.15)
`;

export const StyledLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
