export const baseTrackingEvent = {
  location: "store nav",
  locationDetail: "main nav : account",
  category: "action"
};

export const sendTrackingClickEvent = action => {
  if (!window.adobeAnalytics) {
    console.error("adobeAnalytics error: window.adobeAnalytics not found");
    return;
  }

  window.adobeAnalytics.track("clickevent", {
    ...baseTrackingEvent,
    action
  });
};
