export default {
  translations: {
    walletBalance: "Saldo de la Billetera",
    walletRewards: "Recompensas de la billetera",
    manageMyWallet: "Administrar mi billetera",
    creditMyWallet: "Aplicar crédito a mi billetera",
    error: {
      walletNotFetched:
        "Hubo un error al intentar recuperar la información de tu Billetera. Inténtalo más tarde."
    }
  }
};
