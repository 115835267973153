export default {
  translations: {
    walletBalance: "Saldo portfela",
    walletRewards: "Nagrody w portfelu",
    manageMyWallet: "Zarządzaj moim portfelem",
    creditMyWallet: "Zasil mój portfel",
    usePrepaidCard: "Zrealizuj kartę podarunkową",
    error: {
      walletNotFetched:
        "Podczas próby pozyskania informacji o twoim Portfelu wystąpił błąd. Odczekaj chwilę i spróbuj ponownie."
    }
  }
};
