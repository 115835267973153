export default {
  translations: {
    walletBalance: "Saldo del Wallet",
    walletRewards: "Ricompense sul Wallet",
    manageMyWallet: "Gestisci il mio Wallet",
    creditMyWallet: "Ricarica il mio Wallet",
    error: {
      walletNotFetched:
        "Si è verificato un errore durante il caricamento delle informazioni del tuo Wallet. Ti chiediamo di riprovare più tardi"
    }
  }
};
