// Libs
import React from "react";
import PropTypes from "prop-types";
import {
  CustomAppDefaultProps,
  CustomAppEventsConfigs,
  CustomAppPropTypes
} from "../AppProps";
import { Provider } from "react-redux";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import computedTheme from "../theme";
import Api from "@mooncake/mooncake-gateway-ecom";
import { defaultApiEnv } from "../../mooncake";

// Store
import { initialize } from "./features/app/appSlice";

// Components
import { ThemeProvider } from "@mooncake/ui";
import MicroFrontend from "./MicroFrontend";

class App extends React.Component {
  constructor(props) {
    super(props);

    // select language from micro element component
    if (i18n.language !== props.locale) {
      i18n.changeLanguage(props.locale);
    }

    if (window.tc_events_100 === undefined) {
      window.tc_events_100 = () => {};
    }
  }

  componentDidMount() {
    Api.environment = window.MFE_API_ENV || Api.ENVIRONMENTS[defaultApiEnv];
    this.props.store.dispatch(
      initialize({
        themeKey: this.props.theme,
        locale: this.props.locale
      })
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.themeKey !== this.props.theme ||
      prevProps.locale !== this.props.locale
    ) {
      this.props.store.dispatch(
        initialize({
          themeKey: this.props.theme,
          locale: this.props.locale
        })
      );
    }
    if (i18n.language !== this.props.locale) {
      i18n.changeLanguage(this.props.locale);
    }
  }

  render() {
    const { theme, store, ...props } = this.props;
    return (
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={computedTheme(theme)}>
            <MicroFrontend {...props} />
          </ThemeProvider>
        </I18nextProvider>
      </Provider>
    );
  }
}

// Props
export const AppPropTypes = {
  locale: PropTypes.string,
  theme: PropTypes.string,
  sfccPropsLoaded: PropTypes.bool,
  country: PropTypes.string,
  storeCurrency: PropTypes.string,
  watchSfccProps: PropTypes.bool,
  ...CustomAppPropTypes
};

export const AppDefaultProps = {
  locale: "en",
  theme: "store",
  sfccPropsLoaded: false,
  country: "",
  storeCurrency: "",
  watchSfccProps: false,
  ...CustomAppDefaultProps
};

export const AppEventsConfigs = CustomAppEventsConfigs;

App.propTypes = AppPropTypes;
App.defaultProps = AppDefaultProps;

export default App;
