export default {
  translations: {
    walletBalance: "Saldo da Carteira",
    walletRewards: "Recompensas ca carteira",
    manageMyWallet: "Gerenciar minha carteira",
    creditMyWallet: "Creditar minha carteira",
    error: {
      walletNotFetched:
        "Ocorreu um erro ao tentar recuperar as informações da sua Carteira. Tente novamente mais tarde."
    }
  }
};
