export const authenticatedWalletLink = (isLoggedIn, link) => {
  if (isLoggedIn) {
    window.location.assign(link);
    return;
  }

  sessionStorage.setItem("redirectAfterLogin", link);

  if (window.overlayWidget && window.SitePreferences.ENABLE_OVERLAY) {
    window.overlayWidget.login();
  } else {
    window.location.assign(link);
  }
};
