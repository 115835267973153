export default {
  translations: {
    walletBalance: "Saldo de mi Wallet",
    walletRewards: "Recompensas de tu Wallet",
    manageMyWallet: "Gestionar mi Wallet",
    creditMyWallet: "Recargar mi Wallet",
    error: {
      walletNotFetched:
        "Se ha producido un error al intentar recuperar la información de tu Wallet. Por favor, inténtalo más tarde."
    }
  }
};
