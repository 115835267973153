import colors from "./colors";
import * as componentOptions from "../global/components";

export default {
  colors,
  breakpoints: {
    mobile: 480,
    tablet: 768,
    tabletLarge: 1025,
    desktop: 1280,
    desktopLarge: 1440,
    desktopXLarge: 1920
  },
  ...componentOptions
};
