import { fetchWalletRewards } from "@mooncake/mooncake-gateway-ecom/events/walletRewards";
import { REWARD_CARD_STATUSES } from "@mooncake/ui/lib/specifics/wallet/WalletRewardCard";
import { createSlice } from "@reduxjs/toolkit";
import { setError } from "../../core/features/app/appSlice";
import i18next from "../../core/i18n";

const rewardsSlice = createSlice({
  name: "rewards",
  initialState: {
    rewardsToClaim: []
  },
  reducers: {
    setRewardsToClaim: (state, action) => {
      state.rewardsToClaim = action.payload;
    }
  }
});

export const fetchRewardsAction = (country, currency, locale) => dispatch => {
  return new Promise(resolve => {
    fetchWalletRewards(
      {
        country,
        currency,
        locale: locale.replace("-", "_")
      },
      ({ resource, error }) => {
        if (error) {
          const noWallet = error.response && error.response.status === 404;
          const loggedOut = error.message === "Not logged in";
          if (!noWallet && !loggedOut) {
            dispatch(setError(i18next.t("error.walletNotFetched")));
          }
          resolve();
          return;
        }
        dispatch(
          setRewardsToClaim(
            Array.isArray(resource) &&
              resource
                .filter(
                  reward =>
                    reward.card &&
                    reward.card.type &&
                    reward.card.type === REWARD_CARD_STATUSES.achieved
                )
                .map(reward => reward.account_reward_id)
          )
        );
        resolve();
      }
    )();
  });
};

export const { setRewardsToClaim } = rewardsSlice.actions;

export default rewardsSlice.reducer;
