import { createSlice } from "@reduxjs/toolkit";
import { setError } from "../../core/features/app/appSlice";
import { fetchWallet } from "@mooncake/mooncake-gateway-ecom/events/wallet";
import i18next from "../../core/i18n";
import Cookies from "js-cookie";

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    balance: 0,
    primaryBalance: 0,
    rewardsBalance: 0,
    currency: null,
    hasWallet: true
  },
  reducers: {
    setBalance(state, action) {
      state.balance = parseFloat(action.payload);
    },
    setRewardsBalance(state, action) {
      state.rewardsBalance = parseFloat(action.payload);
    },
    setPrimaryBalance(state, action) {
      state.primaryBalance = parseFloat(action.payload);
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setHasWallet(state, action) {
      state.hasWallet = action.payload;
    }
  }
});

const setDefaults = () => dispatch => {
  dispatch(setHasWallet(true));
  dispatch(setBalance(0));
  dispatch(setPrimaryBalance(0));
  dispatch(setRewardsBalance(0));
  dispatch(setCurrency(null));
};

export const fetchWalletAction = userInfo => dispatch => {
  return new Promise((resolve, reject) => {
    // If userId has not been found in UbiConnect
    if (!userInfo || (userInfo && !userInfo.userId)) {
      setDefaults();
      reject();
    }
    const noWalletCookie = Cookies.get(`noWallet_${userInfo.userId}`);
    // If the noWallet cookie exist we terminate the function
    if (noWalletCookie) {
      setDefaults();
      reject();
    } else {
      fetchWallet(({ resource, error }) => {
        if (error) {
          const noWallet = error.response && error.response.status === 404;
          const loggedOut = error.message === "Not logged in";
          if (loggedOut) {
            setDefaults();
          } else {
            dispatch(setHasWallet(false));
          }
          if (!loggedOut && !noWallet) {
            dispatch(setError(i18next.t("error.walletNotFetched")));
          }
          if (noWallet) {
            const expirationTime = new Date(
              new Date().getTime() + 60 * 60 * 1000
            ); // One hour
            Cookies.set(`noWallet_${userInfo.userId}`, "1", {
              expires: expirationTime
            });
          }
          reject();
          return;
        }
        const {
          primary_balance,
          secondary_balance,
          total_balance,
          currency
        } = resource;
        dispatch(setBalance(total_balance));
        dispatch(setPrimaryBalance(primary_balance));
        dispatch(setRewardsBalance(secondary_balance));
        dispatch(setCurrency(currency));
        resolve(resource);
      })();
    }
  });
};

export const {
  setBalance,
  setPrimaryBalance,
  setRewardsBalance,
  setCurrency,
  setHasWallet
} = walletSlice.actions;

export default walletSlice.reducer;
